import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import { Link as GoLink } from 'react-scroll/modules';
import Layout from '../../../components/layout';
import Seo from '../../../components/seo';
import ListBrand from '../../../components/list-brand';
import ListLink from '../../../components/list-link';
import Image from '../../../components/Image';

const MorsoPage = () => {
  const [itemList, setItemList] = useState('');
  // Ajax設定
  const configJson = {
    method: 'get',
    url: '/update/brand/morso/index.json',
    headers: {
      Accept: 'application/json',
    },
    data: '',
  };
  // Ajax実行
  const dataFetch = async () => {
    axios(configJson)
      .then((response) => {
        setItemList(response.data);
      })
      .catch(() => {
        // console.log(error);
      });
  };
  // List生成
  const itemListArray = (items) => (
    <ul className="flex flex-wrap -mx-4">
      {items.map((item) => (
        <li className="item-content md:w-3/12 w-1/2 px-4" key={item.title}>
          <Link to={`/brand/morso/detail/?id=${item.title}`}>
            <img
              className="full-image"
              src={`/update/brand/morso/img/${item.title}.png`}
              alt=""
            />
            <p>{item.title.toUpperCase()}<span>{item.ordering ? <span className=""> / {item.ordering}</span>: null}</span></p>
          </Link>
        </li>
      ))}
    </ul>
  );
  // Event
  useEffect(() => {
    dataFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout page="brand-page morso">
      <Seo title="MORSO" />
      <section className="page-head">
        <ListBrand />
        <ListLink />
        <h2 className="main-title">
          <Image filename="logo/morso.png" width={160} />
        </h2>
      </section>
      <section className="page-content flex-layout">
        <div className="main-content">
          <div className="content-block">
            <h3 className="sub-title">
              <span>商品一覧</span>
            </h3>
            <p>
              アンデルセンストーブのフラグシップブランドとして長年日本で販売されているデンマークのブランド。
              <br />
              デンマーク王室御用達の称号を受けており創業160年以上の伝統と最新鋭の技術を兼ね備えたストーブを多数ラインナップしています。
            </p>
          </div>
          {itemList
            ? itemList.map((item) => (
                <div
                  id={item.category}
                  className="content-block"
                  key={item.category}
                >
                  <h3 className="sub-title">
                    {item.category.replace('_', ' ').toUpperCase()}
                  </h3>
                  {itemListArray(item.items)}
                </div>
              ))
            : null}
          <div id="pdf" className="content-block">
            <a className="btn pdf full" href="/update/pdf/andersen.pdf" target="_blank" rel="noreferrer">
              PDFカタログ ダウンロード
            </a>
          </div>
        </div>
        <div className="side-list">
        <ul className="sticky">
            {itemList
              ? itemList.map((item) => (
                  <li key={item.category}>
                    <GoLink to={item.category} spy smooth duration={800}>
                      {item.category.replace('_', ' ').toUpperCase()}
                    </GoLink>
                  </li>
                ))
              : null}
            <li>
              <GoLink className="c-attention" to="pdf" spy smooth duration={800}>
                PDFカタログ
              </GoLink>
            </li>
          </ul>
        </div>
      </section>
      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>
            <Link to="/brand/">ブランド</Link>
          </li>
          <li>
            <Link to="/brand/morso/">MORSO</Link>
          </li>
          <li>MORSO商品一覧</li>
        </ul>
      </section>
    </Layout>
  );
};

export default MorsoPage;
